@import '../../custom';

$graphicsSpace: 4rem;

.clr_v3_Header {
  box-shadow: none;
  z-index: 1021;

  &__outside_Speciality {
    @media (max-width: 399px) {
      .fs-5 {
        font-size: 2.6vw !important;
      }
    }

    a:hover {
      opacity: 0.8;
    }
  }

  &__speciality__btn {
    i {
      transform-style: preserve-3d;
      animation: flipIcon 1.5s ease-in-out infinite alternate;
    }

    // span {
    //   max-width: 12rem;
    // }
    @keyframes flipIcon {
      0% {
        transform: rotateY(180deg);
      }

      100% {
        transform: rotateY(0);
      }
    }
  }

  &__navbar {
    padding-bottom: $graphicsSpace;
  }

  &__svgShape {
    width: calc(100% + 1rem);
    margin: 0 -0.5rem -0.1rem;
    height: auto;
  }

  &__brand {
    padding: 0.6rem;
    @include media-breakpoint-down(md) {
      padding: 0.4rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__right {
    ul {
      li {
        display: inline-block;

        a {
          color: $white;
          padding: 0.8rem;
          display: block;
        }
      }
    }

    .clr_v3_profileDropDown {
      &::after {
        display: none;
      }
    }
  }

  &__trendingContainer {
    margin-top: -$graphicsSpace;
  }

  &__exploreDesktop {
    width: 760px;
    box-shadow: 0 0.5rem 0.8rem 0 rgba($black, 0.04);
    max-width: 100%;
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    visibility: hidden;
    opacity: 0;
    translate: 0 2rem;
    transition: all 0.03s ease-in-out;
    list-style-type: none;

    .clr_v3_exploreDesktop__menuIcon {
      width: 4.5rem;
      height: 4.5rem;
      --bs-bg-opacity: 0.08;
      color: $black;

      img {
        width: 3.6rem;
        height: 2.6rem;
        filter: brightness(0);
      }
    }

    li a {
      --bs-bg-opacity: 0.03;

      &:hover,
      &.active {
        --bs-bg-opacity: 0.1;

        .clr_v3_exploreDesktop__menuIcon {
          --bs-bg-opacity: 1;
          color: $white;

          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }

  &__menu-link {
    & > ul > li {
      & > a {
        color: $black;
        position: relative;
        min-height: 6.4rem;

        &::before {
          position: absolute;
          bottom: 0;
          width: 0;
          @include translateX;
          content: '';
          background-color: var(--bs-primary);
          height: 0.15rem;
        }
      }

      &:hover > a,
      &.active > a {
        color: var(--bs-primary);

        // &::before {
        //   width: 100%;
        // }
      }

      &:hover {
        .clr_v3_Header__exploreDesktop {
          visibility: visible;
          opacity: 1;
          translate: 0;
        }
      }
      .clr_v3_menu__premium {
        rotate: 42deg;
        color: $premiumColor;
        margin-left: -0.2rem;
        margin-top: -2.2rem;
      }
    }
  }

  //

  &--navOnly {
    padding-bottom: 0 !important;

    .clr_v3_Header__navbar {
      padding-bottom: 0 !important;

      .clr_v3_Header__brand {
        background-color: var(--bs-light);
      }

      .clr_v3_Header__nav {
        // padding: 1rem 0 !important;

        .clr_v3_Header__right ul li {
          a {
            color: $black !important;

            &:hover {
              color: var(--bs-primary) !important;
            }
          }

          .border-white {
            border-color: $black !important;
          }
        }
      }
    }

    .clr_v3_Header__trendingContainer,
    .clr_v3_Header__background,
    .clr_v3_Header__menuArea,
    .clr_v3_Header__speciality {
      display: none !important;
    }
  }

  &--desktop {
    box-shadow: none;

    .clr_v3_Header__right ul li a {
      color: $black;
      padding: 0;
    }

    .exploretag {
      top: auto;
      bottom: 0.2rem;
      &::after {
        clip-path: polygon(50% 0%, 0 100%, 100% 100%);
        top: auto;
        bottom: 100%;
        background-color: $light;
      }
    }

    @include media-breakpoint-down(lg) {
      .clr_v3_Header__menu-link {
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        background-color: $white;
        height: auto !important;
      }
    }
  }

  //profile
  &--profile {
    .progress {
      height: 2px;
      margin-top: 1rem;
    }
  }
}

.mobile {
  .clr_v3_submenuTopbar {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: 0rem 0rem 1rem 0 rgba($black, 0.05);
  }
}

.clr_v3_submenuTopbar {
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute !important;
  }
}

.clr_v3_Header__menu {
  &__contentCount {
    top: 0rem;
    opacity: 0;
    transition: $transition-base;

    &::before {
      position: absolute;
      top: calc(100% - 1px);
      translate: -50%;
      left: 50%;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
      content: '';
      width: 1rem;
      height: 0.5rem;
      background-color: var(--bs-primary);
    }
  }

  &__item {
    color: $black;
    padding: 1rem 1.2rem;
    border-radius: $border-radius-pill;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .pulseCircle {
      margin-right: 0.6rem;
    }

    &.active,
    &:hover {
      color: var(--bs-primary);
      background-color: var(--bs-light);

      .clr_v3_Header__menu__contentCount {
        opacity: 1;
        top: -1rem;
      }
    }
  }
}

.clr_v3_Header__submenu {
  .swiper-wrapper {
    gap: 1rem;
    @include media-breakpoint-down(md) {
      gap: 0.5rem;
    }
  }

  .clr_v3_Header__menu__item {
    padding: 1.4rem 1rem;
  }
}
.clr_v3_profileDrawer__radio {
  padding: 0.3rem !important;
  .form-check-label {
    box-shadow: 0 0 0 2px rgba(var(--bs-primary-rgb), 0.2);
    i {
      // opacity: 0;
      scale: 0;
      rotate: 45deg;
      transition: $transition-base;
    }
  }
  .form-check-input:checked ~ .form-check-label {
    box-shadow: 0 0 0 2px var(--bs-primary);
    i {
      // opacity: 1;
      scale: 1;
      rotate: 0deg;
    }
  }
}
