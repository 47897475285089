@import '../../../assets/sass/custom';

.bannerCardV1Box {
  background-color: $white;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.14);

  .graph-1,
  .graph-2 {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .graph-1 {
    width: 2px;
    height: 2.5rem;
  }

  .graph-2 {
    width: 2.5rem;
    height: 2px;
  }

  .bannerCardV1BoxPic {
    position: relative;
    overflow: hidden;
    height: auto;

    .overlay {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  .crdV1topPart {
    position: relative;
    padding: 0.3rem 0 0.3rem 1.5rem;
    margin-bottom: 1.2rem;

    .crdV1topPartGraph {
      position: absolute;
      left: 0px;
      height: 2.6rem;
      content: '';
      @include translateY;
      width: 0.2rem;
    }

    .crdV1Speclty {
      &:hover {
        color: $black;
      }
    }

    .cardV1tagSpq {
      border: 0.1rem solid $black;
      margin-right: 1.8rem;
      padding: 0.2rem 0.8rem;
    }
  }

  .cardV1Topic {
    margin-bottom: 0.8rem;
    padding-left: 1.5rem;

    .cardV1ProfilePic {
      width: 5.8rem;
      height: 5.8rem;
      overflow: hidden;
      @include displayProp($justifyContent: center);
      border: 1px solid $light;

      img {
        @include imgAutoSize;
      }
    }

    .cardV1TopicContent {
      position: relative;
      width: 100%;

      a:hover {
        color: $black;
      }
    }
  }

  .bannerCardV1Point {
    background-color: var(--bs-secondary);
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    padding: 0.5rem 1.4rem;
    z-index: 2;
  }

  .bannerCrdV1Btm {
    padding: 1.2rem 1.5rem 1.2rem 0;
  }

  .cardV1Ftr {
    padding-left: 1.5rem;
    margin-top: 1.4rem;

    .cardV1FtrBtn {
      @include cmnTransition;
      display: inline-block;
      overflow: hidden;
      padding: 1rem 1.8rem;

      .cardV1BtnBorder {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        border-width: 1px;
        border-style: solid;
        @include cmnTransition;
        @include translateY;
      }

      .cardV1BtnHover {
        position: absolute;
        width: calc(100% + 2rem);
        height: calc(100% + 2rem);
        @include translateXY;
        opacity: 0;
        @include cmnTransition;
      }

      span {
        position: relative;
        z-index: 1;
        @include cmnTransition;
      }

      svg {
        @include cmnTransition;
      }

      &:hover {
        color: $white;

        .cardV1BtnHover {
          opacity: 1;
        }

        span,
        svg {
          color: $white !important;
        }
      }
    }

    .crdV1sponser {
      width: 40%;
      margin: 0;
      text-align: left;

      .swiper-slide,
      .crdV1sponserIn {
        background-color: $white;
        position: relative;
        height: 4rem;
        width: 100%;

        img {
          @include imgAutoSize;
          position: absolute;
          @include translateY;
          left: 0;
        }
      }
    }
  }
}

.withChanel {
  .bannerCardV1Box .cardV1TopicContent {
    position: relative;
    width: calc(100% - 5.8rem);
    padding-left: 1rem;
  }
}

.cardBannerImage {
  width: 100%;
  height: 100%;
}

// @media screen and (max-width: 1199px) {
//   .bannerCardV1Box {
//     .bannerCardV1BoxPic {
//       height: auto;
//     }
//   }
// }