@import '../../../custom';
$mobileWidth: 320px;
.webNotAvailableLanding {
  &__appstoreBtn {
    &:hover img {
      filter: brightness(0);
    }
    @include media-breakpoint-down(md) {
      img {
        filter: brightness(0);
      }
    }
  }
  &__left {
    width: 40%;
    position: relative;
    @include media-breakpoint-down(lg) {
      width: 50%;
    }
    @include media-breakpoint-down(md) {
      background-color: #0f1d40 !important;
      width: 100%;
      h5 {
        color: #fff !important;
      }
    }
    &__logo {
      width: auto;
      height: 5rem;

      @include media-breakpoint-down(md) {
        height: 4rem;
        filter: brightness(0) invert(1);
      }
    }
    &__h1--ttl {
      line-height: 1.3;

      &__sub {
        font-size: 5.8rem;
      }

      @include media-breakpoint-down(md) {
        color: #fff !important;
        .fs-36 {
          font-size: 4vw;
        }
        .fs-30 {
          font-size: 3.8vw;
        }
        &__sub {
          font-size: 7vw;
        }
      }
    }
    &__slide-content {
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
  }

  &__right {
    position: relative;

    width: 60%;
    @include media-breakpoint-down(lg) {
      width: 50%;
    }
    @include media-breakpoint-down(md) {
      background-color: #2d304a;
      height: 0;
      width: 100%;
      overflow: hidden;
      // &::after{ position: absolute; content: ''; bottom: -4px; left: 0; width: 100%; height:5rem; background-color: #132043; }
    }

    &__mobile__align {
      @include media-breakpoint-up(lg) {
        padding-left: 300px;
      }
    }
    &__mobile-slider {
      padding: 0.5rem;
      width: $mobileWidth;
      height: 540px;
    }
    &__screenshot {
      border-radius: 7rem;
      padding: 0.7rem;
      @include media-breakpoint-down(md) {
        border-radius: 5rem;
      }
      img {
        transition: all 0.3s 0.1s ease-in-out;
        opacity: 0;
      }
    }
    &__circle {
      &__in {
        z-index: 1;
        width: 150px;
        height: 150px;
        background-color: $primary;
        border-radius: 50%;
        position: absolute;
        opacity: 0.5;
        animation: scaleIn 1.8s ease-in-out infinite alternate;
        animation-delay: 0.5s;
        translate: -50% -50% 0;
        &:nth-child(2) {
          animation-delay: 0.8s;
          animation-duration: 2s;
        }
        &:nth-child(3) {
          animation-delay: 1.2s;
          animation-duration: 3s;
        }
      }

      @keyframes scaleIn {
        0% {
          transform: scale(0.4);
          // opacity: .5;
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: sclae(0.4);
          // opacity: 0;
        }
      }
    }
  }

  &__thumbSlideContainer {
    width: 40%;
    right: 10%;
  }
  &__thumbSlideAlign {
    padding-right: calc($mobileWidth + 5rem);
  }
  &__thumbSlide {
    width: auto;
    height: calc(8.5rem * 5);

    .webNotAvailableLanding__thumbItem__icon {
      background-color: var(--bs-light);
      transition: all 0.4s ease-in-out;
      flex-shrink: 0;
    }
    .webNotAvailableLanding__thumbItem__in {
      border: 1px solid transparent;
      transition: all 0.3s ease-in-out;
    }
    .webNotAvailableLanding__thumbItem {
      opacity: 0.4;
      scale: 0.8;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      transform-origin: right;

      &:nth-child(2),
      &:nth-child(4) {
        .webNotAvailableLanding__thumbItem__in {
          margin-right: 6rem;
        }
      }
      &:nth-child(3) {
        .webNotAvailableLanding__thumbItem__in {
          margin-right: 12rem;
        }
      }
      &.swiper-slide-thumb-active {
        scale: 1;
        opacity: 1;
        .webNotAvailableLanding__thumbItem__in {
          border-color: var(--bs-light);
        }
        .webNotAvailableLanding__thumbItem__icon {
          background-color: var(--bs-white);
          box-shadow: -0.2rem 0.2rem 1.4rem 0 rgba(0, 0, 0, 0.1);
          color: var(--bs-primary);
        }
      }
    }
  }
  &__mainslide {
    .swiper-slide-active {
      z-index: 1;
      .webNotAvailableLanding__left__slide-content {
        opacity: 1;
        translate: 0;
      }
      .webNotAvailableLanding__right__screenshot img {
        opacity: 1;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      position: fixed;
      color: var(--bs-black);
      background-color: var(--bs-light);
      padding: 0 2.5rem;
      z-index: 99;
      min-width: 6rem;
      translate: 0 0 0;
      top: auto;
      height: 5rem;
      &::after {
        font-size: 1.6rem;
      }
    }
    .swiper-button-next {
      right: auto;
      left: 40%;
      border-radius: 0 3rem 3rem 0;
      background-color: var(--bs-light);
      bottom: 1.5rem;
    }
    .swiper-button-prev {
      left: auto;
      right: 60%;
      border-radius: 3rem 0 0 3rem;
      background-color: var(--bs-white);
      bottom: 6.5rem;
    }
    @include media-breakpoint-down(lg) {
      .swiper-button-next {
        left: 50%;
      }
      .swiper-button-prev {
        right: 50%;
      }
    }
  }
}
