@import '../../custom';

.clr_v3_card {
  transition: $transition-base;

  .clr_v3_coverImage {
    aspect-ratio: 2/1;
    width: 100%;
    height: auto;

    &:active {
      img {
        scale: 1.1;
      }
    }
  }

  &__content {
    &::after {
      width: 100%;
      aspect-ratio: 1/1;
      height: auto;
      left: 0;
      transform: translate(0, -50%);
      border-radius: 0;
    }
  }

  z-index: 1;

  &--small {
    .clr_v3_coverImage {
      width: 14rem;
      aspect-ratio: 2/1.75;

      @include media-breakpoint-down(md) {
        width: 12rem;
      }

      @include media-breakpoint-down(sm) {
        aspect-ratio: 2/1.9;
      }
    }
  }

  &--featured {
    border-top: 0.6rem solid rgba(var(--bs-primary-rgb), 0.15);
    &:not(.clr_v3_card--descriptionContent_t2, .clr_v3_card--descriptionContent_t3) {
      .clr_v3_card__header {
        padding-left: 3.5rem;
      }
    }

    &__tag {
      top: -1.2rem;
      left: -1.2rem;
    }

    &__icon {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  &--premium {
    &:not(.clr_v3_card--descriptionContent_t2, .clr_v3_card--descriptionContent_t3) {
      .clr_v3_card__header {
        padding-right: 3rem;
        @include media-breakpoint-up(lg) {
          padding-right: 3.5rem;
        }
      }
    }
  }

  &--clinicalVideos {
    .clr_v3_coverImage {
      aspect-ratio: 16/9;

      .clr_v3_card--clinicalVideos__content {
        transition: all 0.4s ease-in-out;
        background-color: rgba(0, 0, 0, 0.15);
      }
      .effect-out-top {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.2) 35%,
          rgba(0, 0, 0, 0) 90%
        );
        translate: 0;
        transition: $transition-base;
        opacity: 1;
      }

      .overlay {
        background-color: rgba($black, 0.35);
        @include transition;

        .playIconAnimation {
          width: 5rem;
          height: 5rem;
          z-index: 4;

          &::before {
            border: 0.2rem solid rgba($white, 0.3);
            border-left-color: $white;
            border-right-color: $white;
            width: 100%;
            height: 100%;
            translate: -50% -50%;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            position: absolute;
            content: '';
            scale: 0.5;
            opacity: 0;

            @keyframes playIconAnimation {
              0% {
                rotate: 0deg;
              }

              100% {
                rotate: 360deg;
              }
            }
          }

          i {
            font-size: 3.5rem;
            @include transition;

            &:before {
              scale: 1.35;
            }
          }
        }
      }

      .clr_v3_card__speaker {
        transform-origin: top left;
        transition: $transition-base;
        scale: 1;
        .hover-primary:hover {
          color: $white !important;
          opacity: 0.6;
        }
      }
      &:hover,
      &:focus,
      &:active {
        .clr_v3_card--clinicalVideos__content {
          background-color: rgba(0, 0, 0, 0.25);
        }

        .effect-out-top {
          filter: backdrop-filter(5px);
          .clr_v3_card__speaker {
            scale: 0.8;
          }
        }
      }
    }
    .clr_v3_card__videoPlayerShow,
    .clr_v3_card__videoPlayerloading {
      opacity: 0;
      animation: videoPlayerShow 0.4s ease-in-out forwards;
      @keyframes videoPlayerShow {
        0% {
          opacity: 0;
          scale: 1.2;
        }
        100% {
          opacity: 1;
          scale: 1;
        }
      }
    }
    .clr_v3_card__videoPlayerShow {
      animation-delay: 0.12s;
    }

    &.loadPlayer {
      .overlay {
        background-color: rgba($black, 0.4);

        .playIconAnimation {
          &::before {
            animation: playIconAnimation 1.2s ease-in-out infinite;
            opacity: 1;
            scale: 1;
          }

          i::before {
            scale: 1;
          }
        }
      }
    }
  }

  //CME
  &--CME {
    &.clr_v3_card--small {
      .ssnCardV1Credit {
        bottom: auto;
        right: auto;
        left: 1rem;
        top: 1rem;
      }
    }

    &:not(.clr_v3_card--small) {
      .overlay {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.5) 20%,
          rgba(0, 0, 0, 0.2) 50%,
          rgba(0, 0, 0, 0.5) 80%,
          rgba(0, 0, 0, 1) 100%
        );
      }
      .btn {
        flex-basis: 0 !important;
      }
      .clr_v3_card__speaker {
        .hover-primary:hover {
          color: $white !important;
          opacity: 0.6;
        }
      }

      .ssnCardV1Credit {
        bottom: 1.5rem;
        right: 1.5rem;
        top: auto;
        left: auto;

        @include media-breakpoint-down(md) {
          bottom: 1rem;
          right: 1rem;
        }
      }
    }
  }

  // Survey
  &--surveyQuiz {
    &.clr_v3_card--small {
      @include media-breakpoint-down(sm) {
        .clr_v3_coverImage {
          width: 9rem;
          aspect-ratio: 2/1.7;
        }
      }
    }

    &__stat {
      li {
        padding-right: 1.4rem;
        margin: 0 1rem;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 0.7rem;
          height: 0.7rem;
          opacity: 0.2;
          transform: translateY(-50%) rotate(45deg);
          background-color: var(--bs-primary);
        }

        @include media-breakpoint-down(sm) {
          margin: 0 0.35rem;
        }

        &:last-child {
          padding: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }

  // gr
  &--gr {
    .clr_v3_coverImage {
      aspect-ratio: 2/0.8;
    }

    &.clr_v3_card--small {
      @include media-breakpoint-up(sm) {
        .clr_v3_coverImage {
          width: 28rem;
        }
      }
    }
  }

  // ebook
  &--ebook {
    .clr_v3_card__imageContainer {
      position: relative;

      &::after,
      &::before {
        position: absolute;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        clip-path: polygon(0 30%, 100% 0%, 100% 70%, 0 100%);
        background-color: rgba(var(--bs-dark-rgb), 0.04);
      }

      &::before {
        clip-path: polygon(0 0, 100% 66%, 100% 100%, 0 100%);
      }
    }

    .clr_v3_coverImage {
      z-index: 1;
      padding-top: 1.2rem;
      border-radius: 0 !important;
      aspect-ratio: auto;

      @include media-breakpoint-down(md) {
        max-width: 18rem;
      }
    }

    .clr_v3_ebook__in {
      padding: 0.5rem;

      &::after,
      &::before {
        position: absolute;
        content: '';
        bottom: calc(100% + 2px);
        left: 0;
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
      }

      &::after {
        background-color: mix(white, $light, 40%);
        height: 0.6rem;
        width: calc(100% - 0.6rem);
        z-index: 1;
      }

      &::before {
        // background-color:mix(white, $light, 60%);
        background-color: $light;
        height: 1.2rem;
        width: calc(100% - 1.2rem);
      }
    }
    &.clr_v3_card--small {
      .clr_v3_card__imageContainer {
        &::after,
        &::before {
          display: none;
        }
      }
      .clr_v3_coverImage {
        max-width: none;
        width: 10rem;
      }
      .clr_v3_ebook__in {
        padding: 0.3rem;
      }
    }
  }

  &--course {
    // .clr_v3_coverImage {
    //   @include media-breakpoint-up(sm) {
    //     height: 24rem;
    //   }
    // }

    .circleProgress {
      width: 1.8rem;
      height: 1.8rem;
    }
    &__slideItem {
      max-width: 75%;
      @include media-breakpoint-up(sm) {
        max-width: 300px;
      }
    }
  }

  // paper
  &__paper {
    &__slider {
      .swiper-wrapper {
        .swiper-slide:last-child {
          margin-right: 0 !important;
        }
      }
    }

    .clr_v3_coverImage {
      height: auto;
      max-width: 18rem;

      @include media-breakpoint-up(sm) {
        max-width: 22rem;
      }
    }
  }

  // doctorVoice
  &--doctorVoice {
    &__doctorVicon {
      &__poll-sec {
        width: 2rem;
        height: 2rem;
        background-color: var(--bs-primary);
        padding: 0 0.2rem;
        border-radius: $border-radius-sm;
      }
    }

    &--color {
      background-color: var(--bs-primary) !important;

      .clr_v3_sponsor {
        border-radius: $border-radius-lg;
        overflow: hidden;
        padding: 0.5rem 1rem;
        background-color: $white !important;
      }

      .text-black,
      .clr_v3_card__footer .text-dark {
        color: $white !important;
      }

      .clr_v3_card__header .bg-primary,
      .clr_v3_polls__label {
        background-color: $white !important;

        .text-white {
          color: var(--bs-primary) !important;
        }
      }

      .clr_v3_polls__label__content {
        .text-black {
          color: $black !important;
        }
      }
    }
  }

  &__sponsor {
    .clr_v3_coverImage {
      aspect-ratio: auto !important;
      height: auto;
      max-height: none !important;
    }

    &--color {
      background-color: var(--bs-light) !important;
    }
  }

  &--certificate {
    .clr_v3_coverImage {
      height: auto;
      max-height: none !important;
      aspect-ratio: auto !important;
    }
  }

  &--related {
    &.clr_v3_card--small {
      .clr_v3_coverImage {
        width: 9rem;
      }
    }
  }

  &--eventCta {
    .clr_v3_coverImage {
      max-height: none;
      height: 18rem;

      @include media-breakpoint-down(sm) {
        height: 16rem;
      }
    }
  }

  &--sampleCta {
    .clr_v3_coverImage {
      max-height: none;
      height: 18rem;

      @include media-breakpoint-down(sm) {
        height: 16rem;
      }
    }
  }

  // content base template
  &--smallContent_t1 {
    .clr_v3_card__container {
      aspect-ratio: 4/1.7;

      @include media-breakpoint-up(sm) {
        aspect-ratio: 4/1.2;
      }
    }

    .clr_v3_coverImage {
      position: absolute !important;
      height: 100%;
      aspect-ratio: unset !important;

      .overlay {
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.6) 50%,
          rgba(0, 0, 0, 0.15) 100%
        );
      }
    }

    .clr_v3_card__header {
      .clr_v3_card__icon {
        background-color: rgba($black, 0.25);
        color: $white !important;
      }
    }

    .clr_v3_card__title {
      color: $white !important;
    }
    .clr_v3_sponsor.text-end {
      text-align: center !important;
      img {
        left: 50%;
        right: auto !important;
        transform: translate(-50%, -50%) !important;
      }
    }
  }

  &--descriptionContent_t2 {
    .clr_v3_coverImage {
      aspect-ratio: 4/1.5;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      @include media-breakpoint-up(sm) {
        aspect-ratio: 4/1;
      }
    }

    .clr_v3_card__container {
      margin-top: -10rem;
    }
  }

  &--descriptionContent_t3 {
    padding: 0 !important;

    .clr_v3_coverImage {
      max-height: none !important;
      aspect-ratio: 4/1.8;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      @include media-breakpoint-up(sm) {
        aspect-ratio: 4/1.2;
      }
    }
  }

  &--smallContent_t4 {
    .clr_v3_coverImage {
      max-height: none !important;
      width: 13rem !important;
      height: 100% !important;
      aspect-ratio: unset;

      @include media-breakpoint-up(sm) {
        width: 20rem !important;
      }
    }

    .clr_v3_card__content {
      width: 130%;
      margin-left: -30%;

      @include media-breakpoint-up(sm) {
        width: 115%;
        margin-left: -15%;
      }
    }
  }

  &--descriptionContent_t5 {
    .clr_v3_card__imageContainer {
      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 40%;
        aspect-ratio: 1/1;
        border-radius: 0.8rem 0.2rem 0 0.2rem;
        background-color: rgba(var(--bs-primary-rgb), 0.25);
      }
    }

    .clr_v3_coverImage {
      width: 15rem !important;
      z-index: 1;
      aspect-ratio: 2/1.5;

      @include media-breakpoint-up(sm) {
        width: 18rem !important;
      }
    }
  }

  // mentor
  &--mentor {
    .clr_v3_coverImage {
      width: 11rem;
      aspect-ratio: 1/1;
      @include media-breakpoint-down(sm) {
        width: 8rem;
      }
    }
    &.clr_v3_card--small {
      .clr_v3_coverImage {
        width: 8rem;
      }
    }
    .btn {
      flex-grow: 0 !important;
      flex-shrink: 0 !important;
    }
    .borderDashed {
      border-top: 1px dashed var(--bs-light);
    }
  }

  &--observership {
    &.clr_v3_card--small {
      .clr_v3_coverImage {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        height: 100%;
      }
    }
    .btn {
      flex-grow: 0 !important;
      flex-shrink: 0 !important;
    }
  }
}

.clr_v3_card__sponsor--wrapper {
  .clr_v3_card {
    .clr_v3_card__body {
      max-width: 300px !important;
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }

    &:after,
    &::before {
      content: '';
      @include translateY;
      position: absolute;
      left: 0;
      width: 4rem;
      height: 80%;
      background-color: var(--bs-primary);
      opacity: 0.6;
      z-index: 0;
      border-radius: 0 1.5rem 1.5rem 0;
    }

    &:after {
      left: auto;
      right: 0;
      border-radius: 1.5rem 0 0 1.5rem;
    }
  }
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .clr_v3_card__icon .fs-1 {
    font-size: 2rem !important;
  }
}

.report-content-popover {
  ul li {
    a {
      color: $black;

      &:hover {
        color: var(--bs-primary) !important;
        background-color: rgba(var(--bs-primary-rgb), 0.15) !important;
      }
    }
  }
}

.clr_v3_rightSideBar,
.clr_v3_leftSideBar {
  .bg-extra-light {
    .clr_v3_card {
      background-color: transparent !important;
      border: 1px solid rgba(var(--bs-dark-rgb), 0.06);

      &:hover,
      &:active {
        border: 1px solid rgba(var(--bs-dark-rgb), 0.25);
      }
    }
  }
}

.speciality-portal--defaultLayout {
  .clr_v3_rightSideBar,
  .clr_v3_leftSideBar {
    .bg-white:not(.clr_v3_card--doctorVoice--color) {
      background-color: $extra-light !important;
    }

    .clr_v3_sideBar_Card_variant-1-big {
      .clr_v3_card.clr_v3_card--small {
        background-color: rgba(var(--bs-primary-rgb), 0.05) !important;
        border: none;

        &:hover,
        &:active {
          background-color: rgba(var(--bs-primary-rgb), 0.15) !important;
        }
      }
    }
  }
}

.withSuggestionBoxPrrnt {
  .clr_v3_card.rounded-4 {
    border-radius: 1rem !important;
  }
  &.bg-soft-primary {
    .clr_v3_icon-btn-1 {
      color: $black;
      &:hover {
        color: rgba(var(--bs-primary));
      }
    }
  }
}

.clr_v3_ContributorCard {
  &__image--fullWidth {
    width: 100% !important;
  }
}
