@import '../../../assets/sass/custom';

.centerModal {
  .modal-content {
    border: none;
    border-radius: $border-radius;
    box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }

  .modal-header {
    position: relative;
    padding: 1.5rem;
    border: none;
    margin-bottom: 0.2rem;

    &::after,
    &::before {
      position: absolute;
      left: 1.5rem;
      bottom: 0.2rem;
      content: '';
      height: 0.1rem;
    }

    &::after {
      background-color: var(--bs-secondary);
      width: 6rem;
    }

    &::before {
      background-color: $light;
      width: calc(100% - 3rem);
    }
  }

  .modal-body {
    padding: 2.5rem;
    @include media-breakpoint-down(md) {
      padding: 1.5rem;
    }

    .table tr td {
      padding: 1.5rem 1.2rem;
    }

    .centerModalFrm {
      .cmnisdCodeParent {
        padding-left: 9rem;
        // overflow: hidden;
        // border-radius: 0.6rem;
        margin-top: 1rem;
        position: relative;

        .cmnisdCodeSelect {
          background-color: #fff;
          border-radius: 0.6rem 0 0 0.6rem;
          // border-right: 0.1rem solid #e7e7e7;
          // color: #5c5c5c;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 9rem;

          select {
            height: 100%;
            // padding: 0 2.6rem 0 1rem;
            padding: 0 1.5rem 0 1rem;
            width: 100%;
            border-color: #e5e5e5;
            border-right: 0;
          }

          i {
            position: absolute;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
            color: #e7e7e7;
          }
        }

        input {
          border-radius: 0 0.6rem 0.6rem 0;
        }
      }

      & > .row {
        margin: -1.3rem 0 0 0;

        .floatingFrmRow {
          padding: 0;
          margin-top: 1.3rem;
        }

        .cmnisdCodeParent {
          margin-top: 1.3rem;

          .floatingFrmRow {
            margin-top: 0;
          }
        }
      }
    }

    .centerModalBttns {
      .cmnBtn,
      .btn {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        &:first-child {
          border-radius: 0.6rem 0 0 0.6rem;
          border-right: 0 !important;

          // &:hover {
          //   background-color: $black;
          // }
        }

        &:last-child {
          border-radius: 0 0.6rem 0.6rem 0;
          border-left: 0 !important;

          // &:hover {
          //   background-color: var(--bs-secondary);
          // }
        }
      }

      .btn {
        &:first-child {
          border-radius: 0.6rem 0 0 0.6rem;
        }

        &:last-child {
          border-radius: 0 0.6rem 0.6rem 0;
        }
      }
    }
  }
}

.centerModal.getRecordingModal {
  .floatingFrmRow {
    & > .form-control:focus ~ label,
    & > .form-control:not(:placeholder-shown) ~ label,
    & > .form-select ~ label {
      width: fit-content;
    }
  }
}

@media screen and (max-width: 767px) {
  .centerModal.modal {
    .centerModalBttns {
      position: fixed;
      bottom: 0;
      width: calc(100% - 3rem);
      padding-bottom: 1rem;
      background-color: $white;
      @include translateX;
    }
    .modal-dialog {
      transform: translateY(30rem);
      position: absolute;
      bottom: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
      display: block;
      min-height: auto;

      .modal-content {
        height: auto;
        max-height: 90svh;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
      }

      .modal-body {
        max-height: calc(100% - 7rem);
        padding-bottom: 6rem !important;
      }
    }

    &.show {
      .modal-dialog {
        // transform: translateY(0);
        transform: none;
      }
    }
  }
}
