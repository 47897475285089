@import '../custom';
@import '../mixins/mixin';

body {
  font-size: $h4-font-size;
  line-height: 1.4;

  * {
    font-family: $font-family-sans-serif !important;
    font-display: auto;
  }
}

i[class^='flaticon-']:before,
i[class*=' flaticon-']:before {
  display: block;
}

html {
  font-size: 62.5% !important;
}

@media (max-width: 575.99px) {
  html.speciality-user-font-size {
    font-size: 58.5% !important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  html.speciality-user-font-size {
    font-size: 51.5% !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  html.speciality-user-font-size {
    font-size: 50% !important;
  }
}

// ===old uesr

@media (max-width: 1799px) {
  html.old-user-font-size {
    font-size: 58.5% !important;
  }
}

@media (max-width: 1599px) {
  html.old-user-font-size {
    font-size: 53.5% !important;
  }
}

@media (max-width: 1199.98px) {
  html.old-user-font-size {
    font-size: 50% !important;
  }
}

@media (max-width: 767.98px) {
  html.old-user-font-size {
    font-size: 53% !important;
  }
}

// ===old uesr

.desktop.container-maxWidth {
  max-width: 760px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
}

a {
  // line-height: 1;
  word-break: break-all;

  &.text-black:hover,
  &.text-dark:hover {
    color: var(--bs-primary) !important;
  }
}

button {
  padding: 0;
  border: none;
  background: transparent;
}

*:focus,
*:focus-visible {
  outline: none;
}

.flex-1 {
  flex: 1;
}

.w-fit-content {
  width: fit-content;
}

.w-100px {
  width: 10rem;
}

.cursorPointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

p:last-child {
  margin: 0 !important;
}

.transition,
a,
button,
::after,
::before {
  @include cmnTransition;
}

i::before {
  transition: none;
}

.placeholder {
  background-color: $light;
  min-height: 0.8rem;
  opacity: 0.8;
}

.react-reveal-container-z-1 {
  .react-reveal {
    z-index: 1;
  }
}

// speciality Selection
.clr_v3_specialitySelection__navbar,
.clr_v3_specialitySelection__footer {
  z-index: 1020;
  @include backdrop-filter;
  background-color: rgba($white, 0.5);
}

.clr_v3_specialitySelection__footer .btn {
  @include media-breakpoint-up(sm) {
    max-width: 40rem;
  }
}

.clr_v3_sponsor {
  @include clr_v3_sponsor;
}

// .clr_v3_coverImage {
//   @include clr_v3_coverImage();
// }

.clr_v3_commentsBox {
  padding-left: 1.2rem;

  &::before {
    position: absolute;
    left: 0;
    width: 1.2rem;
    top: 1rem;
    content: '';
    height: 1rem;
    clip-path: polygon(0 49%, 100% 100%, 100% 0);
    background-color: var(--bs-light);
  }
}

//poll
.clr_v3_polls {
  &__percent {
    // opacity: 0;
    translate: -1rem 0;
    top: 0.8rem;
    right: 0;
  }

  &__label {
    &__content {
      padding: 1.2rem 1rem;

      .translate-middle-x.start-0 {
        transform: translateX(0) !important;
        left: 0 !important;
      }
    }

    .progressBarContainer {
      bottom: 0.8rem;
      // opacity: 0;

      .progress {
        height: 0.3rem;
        width: 0;
      }
    }
  }

  &--active,
  &__input:checked ~ &__label {
    .clr_v3_polls__label__content {
      width: 100% !important;
      padding-right: 4rem !important;
      padding-top: 0.6rem;
      padding-bottom: 1.8rem;
    }

    .clr_v3_polls__percent {
      opacity: 1;
      translate: 0;
    }

    .progressBarContainer {
      opacity: 1;

      .progress {
        width: 100%;
      }
    }
  }
}

//check boxes
.clr_v3_checkbox {
  * {
    @include cmnTransition;
  }

  &__icon {
    background-color: var(--bs-primary);
    width: 2.2rem;
    height: 2.2rem;
    scale: 0.85;

    i {
      font-size: $h4-font-size;
      color: $white;
    }
  }

  &__specialityIcon {
    width: 3.4rem;
    height: 3.4rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  &__specialityList {
    text-align: left;

    .clr_v3_checkbox__specialityIcon {
      --bs-border-opacity: 0;
    }

    .clr_v3_checkbox__label {
      background-color: #f7f7f7;
    }

    .clr_v3_checkbox__input {
      &:checked {
        & ~ .clr_v3_checkbox__label {
          background-color: var(--bs-light);

          .clr_v3_checkbox__specialityIcon {
            --bs-border-opacity: 1;
          }
        }
      }
    }
  }

  &__specialityCard {
    text-align: center;
    width: 100%;
    height: 100%;

    .clr_v3_checkbox__specialityIcon {
      width: 100%;
      height: 6rem;

      // svg,
      //   img {
      //   width: 3.5rem;
      //   height: 3.5rem;
      // }
    }

    .overlay {
      opacity: 0;
    }

    .clr_v3_checkbox__icon {
      box-shadow: 0 0 0 0 rgba($white, 0.12);
      width: 6rem;
      height: 6rem;
      // scale: 0;
      transform: scale(0) !important;
      translate: -50% -50% 0;
    }
  }

  &__input:checked {
    & ~ .clr_v3_checkbox__label {
      .overlay {
        opacity: 0.88;
      }

      .clr_v3_checkbox__specialityIcon svg {
        scale: 0.85;
      }

      .clr_v3_checkbox__icon {
        // scale: 1 !important;
        transform: scale(1) !important;
      }
    }
  }
}

// Floating form

// .form-floating > textarea.form-control {
//   height: 10rem;
//   resize: none;
// }

.form-floating > label {
  color: $gray-600;
  border-radius: $border-radius-sm;
  white-space: normal;
}

.form-floating {
  & > textarea.form-control {
    height: 10rem;
    resize: none;
  }

  & > textarea.form-control:focus,
  textarea.form-control:not(:placeholder-shown) {
    padding: 1.6rem 1.4rem;
  }

  // &>.form-control,
  // &>.form-control-plaintext {

  //   &:focus,
  //   &:not(:placeholder-shown) {
  //     background-color: $white;
  //     border-color: rgba($primary, 0.45);
  //     padding: 1.6rem 1.4rem;
  //   }
  // }

  .form-control:focus ~ label,
  .form-control:not(:placeholder-shown) ~ label,
  .form-select ~ label,
  .form-control-plaintext ~ label {
    padding: 0.2rem 0.4rem;
    background-color: $white;
    color: $black;
    height: auto;
    width: auto;
    border-radius: $border-radius-sm;
    left: 1.2rem;
  }
}

.form-floating-prrnt-smallSelect {
  .form-select {
    background-position: right 1rem center;
    padding-right: 3.5rem !important;
  }
}

.form-check:not(.form-switch) {
  min-height: 2rem;
  padding-left: 0rem;
  position: relative;

  .form-check-input {
    width: 1.8rem;
    height: 1.8rem;
    margin-top: 0;
    margin-right: 1rem;
    margin-left: 0;
    flex-shrink: 0;

    &:checked {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    }
  }

  input[type='radio'] {
    &:checked {
      background-color: var(--bs-primary);
      border-color: var(--bs-primary);
    }
  }

  .form-check-label {
    font-size: $h4-font-size;
    color: $black;
  }

  &.incorrect .form-check-input {
    background-color: $danger !important;
    border-color: $danger !important;

    & ~ .form-check-label {
      color: $danger !important;
    }
  }

  &.correct .form-check-input {
    background-color: $success !important;
    border-color: $success !important;

    & ~ .form-check-label {
      color: $success !important;
    }
  }
}

.form-check-input:checked {
  background-color: var(--bs-primary);
}

.clr_v3_formSwitchLabel {
  display: flex;
  background-color: var(--bs-light);

  .form-check {
    position: relative;
    overflow: hidden;

    &-label {
      position: relative;
      padding: 0.8rem 1.5rem;
      background-color: var(--bs-light);
      color: $black;
      border-radius: $border-radius-pill;
      transition: all 0.2s ease-in-out;
    }

    &-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      z-index: 1;
      opacity: 0;

      &:checked ~ .form-check-label {
        background-color: var(--bs-primary);
        color: $white;
      }
    }
  }
}

// .form-switch {
//   padding-left: 3rem;
//   margin: 0;
//   min-height: 2rem;
//   .form-check-input {
//     width: 2.5rem;
//     margin-left: -3rem;
//   }
// }

.fullOpacity {
  .form-check-input,
  .form-check-label {
    opacity: 1 !important;
  }
}

.cmnFileUploadShow {
  margin: -1rem 0 0 -1rem;

  .cmnFileUploadBox {
    padding: 0 0 0 1rem;
    margin-top: 1rem;

    .cmnFileUploadBoxIn {
      overflow: hidden;
      position: relative;
      height: 11rem;
    }

    .cmnFileUploadRemove {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      width: 3rem;
      height: 3rem;
      @include displayProp($justifyContent: center);
      background-color: rgba(255, 255, 255, 0.92);
    }
  }
}

// @include media-breakpoint-down(md) {
//   .form-control,
//   .form-control-plaintext,
//   .form-select,
//   .form-control ~ label,
//   .form-select ~ label,
//   textarea.form-control:not(:placeholder-shown),
//   textarea.form-control:focus {
//     padding: 1.5rem 1.4rem;
//   }

//   .form-control:not(textarea),
//   .form-control-plaintext,
//   .form-select {
//     height: 5rem;
//   }
// }

.backdrop-filter {
  @include backdrop-filter;
}

// fixation----

.mobile {
  .clVideosFilterMobile {
    bottom: 11rem;
    right: 2%;
    width: 6rem;
    height: 6rem;
    box-shadow: 0 0.5rem 1.2rem 0 rgba(0, 0, 0, 0.45) !important;
    z-index: 991;

    span {
      top: 0.5rem;
      height: 2.2rem;
      min-width: 2.2rem;
      right: 0.5rem;
      box-shadow: 0 0.5rem 1.2rem 0 rgba(0, 0, 0, 0.45) !important;
    }
  }
}

.exploretag {
  top: 0.5rem;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    width: 1rem;
    height: 0.5rem;
    @include translateX;
    background-color: var(--bs-light);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }

  @include media-breakpoint-down(md) {
    top: 0.1rem;
  }
}

.clr_v3_premium__tag {
  border-radius: 0 0 0 50%;
  top: 0;
  right: 0;
  background: $premiumBgColor;

  &__icon {
    margin: 12% 0 0 -25%;
    rotate: 45deg;
  }
}

.loader-line {
  width: 100%;
  height: 4px;
  overflow: hidden;
  position: fixed;
  top: 0;
  // background-color: #ddd;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  z-index: 1035;
}

.loader-line:before {
  content: '';
  position: absolute;
  left: -50%;
  top: 0;
  height: 4px;
  width: 40%;
  background-color: $primary;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}

// body body.body--ios-device-only {
//   -webkit-overflow-scrolling: touch !important;
// }

// body body.body--ios-device-only::-webkit-scrollbar-track {
//   background-color: transparent;
//   box-shadow: none;
//   display: none;
// }

// body.body--ios-device-only::-webkit-scrollbar {
//   background-color: transparent;
//   box-shadow: none;
//   display: none;

// }

// body.body--ios-device-only::-webkit-scrollbar-thumb {
//   background-color: transparent;
//   box-shadow: none;
//   display: none;

// }
