@mixin cmnTransition($time: 0.3s, $function: ease-in-out) {
  transition: all $time $function;
}

@mixin displayProp($justifyContent: space-between) {
  display: flex;
  align-items: center;
  justify-content: $justifyContent;
}

@mixin translateXY {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@mixin translateY {
  transform: translateY(-50%);
  top: 50%;
}

@mixin translateX {
  transform: translateX(-50%);
  left: 50%;
}

@mixin imgAutoSize {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

@mixin clr_v3_sponsor($w: 36%, $h: 3.2rem, $w-sm: 14rem, $h-sm: 3.6rem) {
  width: $w;

  .swiper-slide,
  .clr_v3_sponsor_slide {
    height: $h;
  }

  .swiper-slide.clr_v3_sponsor_slide {
    opacity: 0 !important;

    &.swiper-slide-active {
      opacity: 1 !important;
    }
  }

  @include media-breakpoint-up(sm) {
    width: $w-sm;

    .swiper-slide,
    .clr_v3_sponsor_slide {
      height: $h-sm;
    }
  }
}

@mixin rippleEffect {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);

  &::after {
    content: '';
    background: rgba(var(--bs-black-rgb), 0.4);
    display: block;
    position: absolute;
    width: 30rem;
    height: 30rem;
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    z-index: -1;
  }

  &:active:after {
    padding: 0;
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0.9;
    -webkit-transition: 0s;
    transition: 0s;
  }
}

@mixin shineEffect {
  position: relative;
  overflow: hidden;
  translate: 0 0 0;

  &::after {
    content: '';
    top: 0;
    translate: 100% 0 0;
    width: 100%;
    height: 200%;
    position: absolute;
    z-index: 1;
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.8)),
      color-stop(99%, rgba(128, 186, 232, 0)),
      color-stop(100%, rgba(125, 185, 232, 0))
    );
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    /* Opera 11.10+ */
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    /* IE10+ */
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1);
    /* IE6-9 */
  }
}
@keyframes shineEffectAnimation {
  0% {
    translate: -100% 0 0;
  }

  100% {
    translate: 100% 0 0;
  }
}

@mixin backdrop-filter($blur: 6px) {
  backdrop-filter: blur($blur);
  -webkit-backdrop-filter: blur($blur);
}
