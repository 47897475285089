.clr_v3_swiper.swiper {
    .swiper-pagination {
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        gap: .6rem;

        @include media-breakpoint-down(sm) {
            padding: 1rem;
        }

        .swiper-pagination-bullet {
            background-color: var(--bs-primary);
            --bs-bg-opacity: .15;
            border-radius: .6rem;
            margin: 0;
            width: 5px;
            height: 5px;
            transition: all .3s ease-in-out;

            &.swiper-pagination-bullet-active {
                width: 2rem;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        translate: 0 -50% 0;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        background-color: $white;
        border-radius: $border-radius-circle;
        background-color: $white;
        box-shadow: $box-shadow;
        margin: 0;

        &::after {
            font-size: 1.4rem;
            color: var(--bs-primary);
        }

        &.swiper-button-disabled {
            opacity: 0;
        }
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }
}